import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-new-page-select',
	templateUrl: './new-page-select.component.html',
	styleUrls: ['./new-page-select.component.scss'],
})
export class NewPageSelectComponent implements OnInit {
	pageTitle = '';
	searchMessage = '';
	placeholderMessage = '';
	originalOptionList = [];
	optionList = [];
	displayField: any;
	valueField = '';
	multiSelect = false;
	needFilterSearch = true;
	needTranslation = false;
	selected: any;
	format = '';
	cancelText = '';
	confirmText = '';
	isStaff = false;
	isOrg = false;
	instantClose = false;
	staffNumber = 50;
	staffSearchQuery = [];
	currentPage = 1;
	preventRepeatReuqest: boolean = false;
	staffTotal = 0;
	isNotifier = false;
	lastSearchTime = new Date().getTime();
	needShowColor = false;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
		public localStorageService: LocalStorageService) {
		let params = this.navParams.data;
		this.pageTitle = params.pageTitle ? params.pageTitle : '';
		this.placeholderMessage = params.placeholderMessage ? params.placeholderMessage : '';
		this.originalOptionList = params.optionList ? params.optionList : [];
		if (params.displayField instanceof Array) {
			this.displayField = params.displayField.length > 0 ? params.displayField : [];
		} else {
			this.displayField = params.displayField ? params.displayField : '';
		}
		this.valueField = params.valueField ? params.valueField : '';
		this.multiSelect = params.multiSelect ? params.multiSelect : false;
		if (params.needFilterSearch === false) {
			this.needFilterSearch = false;
		}
		
		if (this.multiSelect) {
			this.selected = params.selected ? params.selected : [];
		} else {
			this.selected = params.selected ? params.selected : '';
		}
		this.needTranslation = params.needTranslation ? params.needTranslation : false;
		this.format = params.format ? params.format : '';
		this.cancelText = params.cancelText ? params.cancelText : 'aramark_cancel';
		this.confirmText = params.confirmText ? params.confirmText : 'aramark_confirm';
		this.instantClose = params.instantClose ? params.instantClose : false;

		this.isStaff = params.isStaff;
		this.isNotifier = params.isNotifier;
		this.isOrg = params.isOrg;

		this.staffSearchQuery = params.searchQuery;
		this.staffTotal = params.staffTotal;

		this.needShowColor = params.needShowColor ? params.needShowColor : '';
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = this.pageTitle;
		if (dd.env.platform !== 'notInDingTalk') {
			dd.biz.navigation.setTitle({
				title: this.pageTitle // 钉钉设置导航栏标题
			});
		}
		this.optionList = this.originalOptionList;
		this.processDisplayValue(this.optionList);

		const that = this;
		window.onpopstate = function () {
			const a = document.getElementsByTagName('app-new-page-select');
			if (a.length > 0) {
				setTimeout(() => {
					that.modalController.dismiss();
					// history.back();
				}, 100)
			}
		};
	}

	markSelected(item) {
		if (this.multiSelect) {
			this.selected = this.selected ? this.selected : [];
		} else {
			this.selected = this.selected ? this.selected : '';
		}
		
		if (!this.multiSelect) {
			if (this.valueField !== '$self') {
				this.selected = item[this.valueField];
			} else {
				this.selected = item;
			}
		} else {
			if (this.valueField !== '$self') {
				if (this.selected.indexOf(item[this.valueField]) === -1) {
					this.selected.push(item[this.valueField]);
				} else {
					this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
				}
			} else {
				if (this.selected.indexOf(item) === -1) {
					this.selected.push(item);
				} else {
					this.selected.splice(this.selected.indexOf(item), 1);
				}
			}
		}
		if (!this.multiSelect && this.instantClose) {
			this.confirm();
		}
	}

	isSelected(item) {
		if (this.selected) {
			if (!this.multiSelect) {
				if (this.valueField !== '$self') {
					return this.selected === item[this.valueField];
				} else {
					return this.selected === item;
				}
			} else {
				if (this.valueField !== '$self') {
					return this.selected.indexOf(item[this.valueField]) !== -1;
				} else {
					return this.selected.some(element => element.id === item.id);
				}
			}
		}
		return false;
	}

	filterOptionList() {
		// const currenTime = new Date().getTime();
		// const a = currenTime - this.lastSearchTime;
		// console.log(a);
		// if (this.lastSearchTime && a < 8000) {
		// 	this.lastSearchTime = new Date().getTime();
		// 	return;
		// }

		// if (this.isStaff || this.isNotifier) { // 员工或通知对象
		if (this.searchMessage) {
			// 		let hasSearched = false;
			// 		if (this.staffSearchQuery) {
			// 			this.staffSearchQuery.forEach((res) => {
			// 				if (res.key === 'full_name') {
			// 					res.value = this.searchMessage;
			// 					hasSearched = true;
			// 				}
			// 			})
			// 		} else {
			// 			this.staffSearchQuery = [];
			// 		}

			// 		let query;
			// 		if (!hasSearched) {
			// 			const data = {
			// 				condition: "like",
			// 				connection: "and",
			// 				isValueADigital: false,
			// 				key: "full_name",
			// 				value: this.searchMessage
			// 			};
			// 			query = this.staffSearchQuery.concat(data);
			// 		} else {
			// 			query = this.staffSearchQuery;
			// 		}

			// 		this.currentPage = 0;
			// 		const param = {
			// 			pagingTool: {
			// 				currentPage: this.currentPage + 1,
			// 				pageSize: 50
			// 			},
			// 			// queryCriterias: [],
			// 			queryCriterias: query,
			// 			queryOrderBies: [{
			// 				columnName: 'code',
			// 				orderType: 'DESC'
			// 			}]
			// 		};
			// 		if (this.isStaff) {
			// 			const that = this;
			// 			const promise = new Promise<void>((resolve, reject) => {
			// 				that.dataService.getStaffList(param).subscribe(res => {
			// 					if (res && res.STATUS === 0) {
			// 						this.staffTotal = res.TOTAL_COUNT;
			// 						this.currentPage ++;
			// 						this.optionList = res.DATA;
			// 						this.processDisplayValue(this.optionList);

			// 						resolve();
			// 					} else {
			// 						that.appService.toastTip(res.MSG, false);
			// 						reject();
			// 					}
			// 				});
			// 			});
			// 			return promise;
			// 		} else if (this.isNotifier) {
			// 			const that = this;
			// 			that.dataService.getEmployeesDivisionalList(param).then(res => {
			// 				if (res && res.STATUS === 0) {
			// 					res.DATA.forEach(v => {
			// 						v.tempOrgName = v.orgs && v.orgs.length ? v.orgs[0]['full_name'] : '';
			// 					});
			// 					this.staffTotal = res.TOTAL_COUNT;
			// 					this.currentPage ++;
			// 					this.optionList = res.DATA;
			// 					this.processDisplayValue(this.optionList);

			// 				} else {
			// 					that.appService.toastTip(res.MSG, false);
			// 				}
			// 			});
			// 		}


			// 	} else {
			// 		this.optionList = this.originalOptionList;
			// 		this.processDisplayValue(this.optionList);
			// 	}
			// } else { // 非员工
			// 	if (this.searchMessage) {
			let filteredList = [];
			if (this.displayField instanceof Array) {
				filteredList = this.originalOptionList.filter(item => {
					let filterRawInfo = '';
					this.displayField.forEach(field => {
						filterRawInfo += item[field];
					});
					return filterRawInfo.indexOf(this.searchMessage) !== -1;
				});
			} else {
				filteredList = this.originalOptionList.filter(item => item[this.displayField].indexOf(this.searchMessage) !== -1);
			}
			this.optionList = filteredList;
		} else {
			this.optionList = this.originalOptionList;
		}
		this.processDisplayValue(this.optionList);
		// }
	}

	async cancel() {
		await this.modalController.dismiss();
		history.back();
	}

	async confirm() {
		if (this.selected instanceof Array) {
			this.selected.forEach(item => {
				if (item.hasOwnProperty('finalDisplay')) {
					delete item.finalDisplay;
				}
			});
		} else {
			if (this.selected.hasOwnProperty('finalDisplay')) {
				delete this.selected.finalDisplay;
			}
		}

		await this.modalController.dismiss(this.selected);
		history.back();
	}

	processDisplayValue(list: any[]) {
		if (this.displayField instanceof Array) {
			if (this.format) {
				list.forEach(item => {
					item.finalDisplay = this.format;
					this.displayField.forEach((field, index) => {
						item.finalDisplay = item.finalDisplay.replace(('$' + (index + 1)), item[field]);
					});
				});
			}
		} else {
			list.forEach(item => {
				item.finalDisplay = item[this.displayField];
			});
		}
	}

	loaderMore(event: any) {
		if (this.staffTotal > this.originalOptionList.length) {
			if (this.isStaff) {
				this.getStaffData(event);
			} else if (this.isNotifier) {
				this.getNotifierfData(event);
			} else if (this.isOrg) {
				this.getOrgData(event);
			}
		} else {
			event.target.complete();
		}
	}

	async getStaffData(event): Promise<any> {
		const param = {
			pagingTool: {
				currentPage: this.currentPage + 1,
				pageSize: 50
			},
			queryCriterias: this.staffSearchQuery,
			queryOrderBies: [{
				columnName: 'code',
				orderType: 'DESC'
			}]
		};
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getStaffList(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					event.target.complete();
					this.currentPage++;
					this.originalOptionList = this.originalOptionList.concat(res.DATA);
					this.filterOptionList();
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
	}

	async getNotifierfData(event): Promise<any> {
		const param = {
			pagingTool: {
				currentPage: this.currentPage + 1,
				pageSize: 50
			},
			queryCriterias: this.staffSearchQuery,
			queryOrderBies: [{
				columnName: 'code',
				orderType: 'DESC'
			}]
		};
		const that = this;
		that.dataService.getEmployeesDivisionalList(param).then(res => {
			if (res && res.STATUS === 0) {
				event.target.complete();
				res.DATA.forEach(v => {
					v.tempOrgName = v.orgs && v.orgs.length ? v.orgs[0]['full_name'] : '';
				});
				this.currentPage++;
				this.originalOptionList = this.originalOptionList.concat(res.DATA);
				this.filterOptionList();
			} else {
				that.appService.toastTip(res.MSG, false);
			}
		});
	}

	async getOrgData(event): Promise<any> {
		const param = {
			pagingTool: {
				currentPage: this.currentPage + 1,
				pageSize: 50
			},
			queryCriterias: this.staffSearchQuery,
			queryOrderBies: []
		};
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getProjectCostCenterCodeListTable(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					event.target.complete();
					this.currentPage++;
					this.originalOptionList = this.originalOptionList.concat(res.DATA);
					this.filterOptionList();
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
	}
}